import { createClient } from 'contentful';


export const getContentfulClient = (accessToken, space) => createClient({
  accessToken,
  space,
});

export default {
  getContentfulClient,
};
